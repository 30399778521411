<template>
  <material-chart-card
    class="v-card--material-chart mb-4"
    color="info darken-2"
    library="apexcharts"
    type="heatmap"
    :options="options"
    :series="orderBy(lab_results_hourly.series, 'sort', -1)"
    chart-height="268"
    chart-class="mt-n4 mb-2"
  >
    <h4 class="text-h6 font-weight-light">
      {{ $t('charts.hourly_lab_result_volume') }}
    </h4>
    <div
      v-if="lab_results_hourly.series.length"
      class="category d-inline-flex font-weight-light"
    >
      Average hourly volume over the last 4 weeks
    </div>

    <template #actions>
      <v-icon
        class="mr-2"
        small
      >
        mdi-clock-outline
      </v-icon>
      <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ $moment(lab_results_hourly.time).diff() | duration('humanize', true) }}</span>
      <v-spacer />
      <card-reload
        :loading="loading.lab_results_hourly"
        @reload="fetchHourlyResults({ no_cache: true, days: 28 })"
      />
    </template>
  </material-chart-card>
</template>
<script>
import Vue2Filters from 'vue2-filters';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  components : {
    CardReload: () => import('@/components/CardReload.vue'),
  },
  mixins: [Vue2Filters.mixin],
  data: function() {
    return {
      renderKey: 0,
      colors: [
        '#c6d935',
        '#ffcc05',
        '#f79839',
        '#ec2024',
        '#ae1618',
      ],
    }
  },
  computed: {
    ...mapState('charts', ['loading', 'lab_results_hourly']),
    updated () {
      return this.$moment(this.lab_results_hourly.time).diff()
    },
    options () {
      return {
        chart: {
          foreColor: '#ddd',
          fontFamily: 'Roboto, sans-serif',
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          height: '300px',
        },
        plotOptions: {
          heatmap: {
            shasdeIntensity: 0.5,
            radius: 0,
            useFillCsolorAsStroke: true,
            colorScale: {
              ranges: this.colorRanges,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ['#0080ad'],
        },
        legend: {
          position: 'bottom',
          floating: true,
          fontSize: '10.5px',
        },
        tooltip: {
          x: {
            show: true,
            formatter: function (value) {
              return timeFormatter(value, { minute: '2-digit' })
            },
          },
          y: {
            formatter: function (value) {
              return value
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return typeof value == 'string' ? value.substring(0,3) : value;
            },
          },
        },
        xaxis: {
          labels: {
            rotate: -55,
            style: {
              fontSize: '10px',
            },
            formatter: timeFormatter,
          },
        },
      }
    },
    colorRanges () {
      // Generate the color ranges with 7 steps
      let ranges = []
      let max = Math.ceil(this.lab_results_hourly?.max / 10) * 10

      for (let i = 0; i < 5; i++) {
        ranges.push({
          from: Math.max(1, i * Math.round(max / 5)),
          to: (i + 1) * Math.round(max / 5),
          color: this.colors[i],
        });
      }

      return ranges
    },
  },
  watch: {
    colorRanges: {
      handler: function (val) {
        this.options.plotOptions.heatmap.colorScale.ranges = val
      },
      deep: true,
    },
  },
  created () {
    if (this.$moment().diff(this.lab_results_hourly.time, 'hours') > 1) {
      this.fetchHourlyResults({ no_cache: false, days: this.days })
    }
    setTimeout(() => { this.renderKey = 100 }, 100)
  },
  methods: {
    ...mapMutations("charts", ["setHourlyChartData"]),
    ...mapActions("charts", ["fetchHourlyResults"]),
  },
}

function timeFormatter (value, options = {}) {
  let time = new Date()
  time.setHours(value.split(':')[0])
  time.setMinutes(value.split(':')[1])
  return time.toLocaleTimeString(navigator.language, {...options, ...{ hour: 'numeric', hour12: true }})
}
</script>
<style>
.apexcharts-tooltip {
  color: #000000;
}
.apexcharts-tooltip .apexcharts-tooltip-series-group.active {
  background: #ffffff !important;
}</style>
